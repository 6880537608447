import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import { buttonVariants } from "./variants/button";

const breakpoints = createBreakpoints({
  xs: "375px",
  md: "768px",
  lg: "1440px",
});

const theme = {
  fonts: {
    heading: "Clarity Nuvo, Saxvon",
    body: "Clarity Nuvo, Saxvon",
  },
  textStyles: {
    mainHeader: {
      fontSize: "64px",
      lineHeight: "72px",
      letterSpacing: "2.1px",
    },
    defaultHeader: {
      fontSize: "48px",
      lineHeight: "56px",
      letterSpacing: "1.6px",
    },
    smallTitle: {
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "3.2px",
    },
    "paragraph-1": {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "32px",
      letterSpacing: "3.2px",
    },
    "paragraph-2": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.4px",
    },
    "paragraph-4": {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "2.05px",
    },
    buttonLabel: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "1.2px",
      color: "buttonColor",
      fontWeight: "normal",
    },
    subtitle: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "32px",
      letterSpacing: "3.2px",
    },
    "subtitle-2": {
      fontSize: { base: "24px", lg: "40px" },
      fontWeight: "300",
      lineHeight: { base: "40px", lg: "48px" },
      letterSpacing: { base: "2.7px", lg: "4.6px" },
    },
    addressLabel: {
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "1.5px",
    },
    inputLabel: {
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "1.4px",
      color: "buttonColor",
    },
    menuLink: {
      fontSize: { base: "20px", md: "13px" },
      lineHeight: { base: "28px", md: "24px" },
      letterSpacing: { base: "1.9px", md: "1.2px" },
      color: "footerValue",
    },
    inputValue: {
      fontSize: { base: "16px", md: "14px " },
      fontWeight: "400",
      lineHeight: { base: "24px", md: "22px" },
      letterSpacing: { base: "1.1px", md: "0.93px" },
      color: "white",
      fontFamily: "ClarityNuvo",
    },
    footerText: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "1.2px",
      color: "footerValue",
    },
    "paragraph-3": {
      fontSize: { base: "12px", lg: "14px" },
      lineHeight: { base: "20px", lg: "24px" },
      letterSpacing: { base: "0.3px", lg: "0.4px" },
      color: "footerValue",
    },
  },
  colors: {
    primaryBlack:
      "linear-gradient(135.39deg, rgba(0, 0, 0, 1.2e-05) -0.7%, rgba(0, 0, 0, 0.084) 100%), #131313",
    footerBlack:
      "linear-gradient(135.39deg, rgba(0, 0, 0, 9.2e-05) -0.7%, rgba(0, 0, 0, 0.644) 100%), #1F2120",
    blackText: "#1D1C1D",
    buttonColor: "#D1D4D2",
    buttonBg: "#234145",
    paragraphColor: "#A3A9A6",
    subtitleColor: "#ECDCDB",
    inputColor: "#606663",
    addressLabelColor: "#1F2120",
    addressValueColor: "#4A4F4D",
    footerValue: "#BDC3C7",
    menuBg: "#131313",
    activeLink: "#528D95",
    inputBorder: "#757D79",
    serviceCard:
      "linear-gradient(133.87deg, rgba(0, 0, 0, 1.2e-05) -4.38%, rgba(0, 0, 0, 0.084) 106.03%)",
    serviceCard2:
      "linear-gradient(133.87deg, rgba(0, 0, 0, 1.2e-05) -4.38%, rgba(0, 0, 0, 0.084) 106.03%), #FFFFFF",
  },
  breakpoints,
  components: {
    ...buttonVariants,
  },
};

export default extendTheme(theme);
