export const buttonVariants = {
  Button: {
    baseStyle: {
      _focus: {
        shadow: "none",
      },
      _hover: {
        textDecoration: "none",
      },
    },
    variants: {
      primary: {
        bg: "buttonBg",
        padding: "16px",
        textStyle: "buttonLabel",
        textTransform: "uppercase",
        borderRadius: "none",
      },
    },
  },
};
